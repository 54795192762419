import './App.css';

function App() {
  return (
    <div className="App">
        <header className="App-header">
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
            <div className="topnav" id="myTopnav">
                <a href="" className="active">Home</a>
                <a href="download.html">Download</a>
                <a href="help.html">Help</a>
                <a href="contact.html">Contact</a>
                <a href="javascript:void(0);" className="icon" onClick="myFunction()">
                    <i className="fa fa-bars"></i>
                </a>

            </div>
      </header>
    </div>
  );
}

export default App;
